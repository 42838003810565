<script>
export default {
  messages: {
    notifs: {
      new_comment: 'Nouveau commentaire de leçon',
      password_changed: 'Votre mot de passe a été changé',
      training_item_unblocked: 'Nouvelle(s) leçon(s) disponible(s)',
      weekly_recap: 'Récapitulatif hebdomadaire de votre progression',
    },
  },
  data() {
    return {
      isLoading: false,
      accept_email_notifications: true,
      email_settings: {},
    };
  },
  computed: {
    store() {
      return this.$store.state.store.data;
    },
    authCustomer() {
      return this.$store.getters['auth/customer'];
    },
  },
  methods: {
    handleAllEmailNotifs() {
      this.$store.dispatch('auth/update', {
        accept_email_notifications: this.accept_email_notifications,
      })
        .then(() => this.$showMessage.success())
        .catch((error) => {
          this.accept_email_notifications = !this.accept_email_notifications;
          this.$errorHandlers.axios(error);
        });
    },
    handleEmailNotifSettings(notifType) {
      const email_settings = { ...this.email_settings };
      this.$store.dispatch('auth/update', { email_settings })
        .then(() => this.$showMessage.success())
        .catch((error) => {
          this.email_settings[notifType] = !this.email_settings[notifType];
          this.$errorHandlers.axios(error);
        });
    },
  },
  created() {
    this.accept_email_notifications = this.authCustomer.accept_email_notifications;
    this.email_settings = { ...this.authCustomer.email_settings };
  },
};
</script>

<template>
  <div class="box content is-size-8-mobile">
    <h1 class="is-size-5-mobile">
      Notifications par email
    </h1>
    <p>Ces options nous permettent de vous avertir par email quand&nbsp;:</p>
    <ul>
      <li>Quelqu'un a répondu à un de vos commentaires</li>
      <li>Votre mot de passe a été changé</li>
      <li>...Et plus encore</li>
    </ul>
    <!-- <p>
      Que vous activiez ou non les notifications par email, vous aurez toujours
      les notifications dans votre espace en cas de besoin.
    </p> -->
    <p>
      <b-switch
        v-model="accept_email_notifications"
        @input="handleAllEmailNotifs()"
      >
        Toutes les notifications
      </b-switch>
    </p>
    <template v-if="accept_email_notifications">
      <h2 class="is-size-4 is-size-7-mobile">
        Activez/Désactivez des notifications spécifiques
      </h2>
      <p v-for="(notifText, notifType) in $options.messages.notifs" :key="notifType">
        <b-switch
          v-model="email_settings[notifType]"
          @input="handleEmailNotifSettings(notifType)"
        >
          {{ notifText }}
        </b-switch>
      </p>
    </template>
  </div>
</template>
